import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API from '../utils/api';
import encodeParams from '../utils/queryParam'

const initialState = {
  isLoading: false,
  data: {
    currentPage: 0,
    hasNext: false,
    hasPrevious: false,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    items: []
  },
  detail: {},
  customerDetail: {}
};

const slice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    getList(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    setPending(state, action) {
      state.isLoading = true;
    },
    getById(state, action) {
      state.isLoading = false;
      state.detail = action.payload;
    },
    clearById(state, action) {
      state.isLoading = false;
      state.detail = {};
    },
    getCustomerDetail(state, action) {
      state.isLoading = false;
      state.customerDetail = action.payload;
    },
  }
});

export const reducer = slice.reducer;

export const getList = (parameter) => async (dispatch) => {
    dispatch(slice.actions.setPending());
    const url = `${API.URL_QUOTATION}?${encodeParams(parameter)}`
    const response = await axios.get(url);
    dispatch(slice.actions.getList(response.data.data));
};

export const getQuotationById = (id) => async (dispatch) => {
  dispatch(slice.actions.setPending());
  if (id === 'create') {
    dispatch(slice.actions.clearById());
  } else {
    const url = `${API.URL_QUOTATION}/${id}`
    const response = await axios.get(url);
    dispatch(slice.actions.getById(response.data.data));
  }
};

// export const getCustomerDetail = (id, businessId, customerId) => async (dispatch) => {
//   dispatch(slice.actions.setPending());
//   const url = `${API.URL_CUSTOMER}/${id}/action/GetCustomerInformation/${businessId}/${customerId}`
//   const response = await axios.get(url);
//   dispatch(slice.actions.getCustomerDetail(response.data));
// };

export const getCustomerDetail = async (id, businessId, customerId) => {
  try {
    const url = `${API.URL_CUSTOMER}/${id}/action/GetCustomerInformation/${businessId}/${customerId}`;
    const res = await axios.get(url);
    return res.data.data;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const getCustomerContact = async (id, businessId, customerId) => {
  try {
    const url = `${API.URL_CUSTOMER}/${id}/action/GetCustomerContactList/${businessId}/${customerId}`;
    const res = await axios.get(url);
    return res.data.data;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const getAccount = async (id, businessId) => {
  try {
    const url = `${API.URL_QUOTATION}/payment_account?OrgId=${id}&BusinessId=${businessId}`;
    const res = await axios.get(url);
    return res.data.data;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};


export const getProject = async (id, businessId) => {
  try {
    const url = `${API.URL_PROJECT}/${id}/action/GetProjectList/${businessId}`;
    const res = await axios.get(url);
    return res.data.data;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const getProduct = async (id, businessId) => {
  try {
    const url = `${API.URL_PRODUCT}/${id}/action/GetProductList/${businessId}`;
    const res = await axios.get(url);
    return res.data.data;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const getQuotationCalculate = async (data) => {
  try {
    const url = `${API.URL_QUOTATION}/quotation_calculate`;
    const res = await axios.post(url, data);
    return res.data.data;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const createQuotation = async (data) => {
  try {
    const url = `${API.URL_QUOTATION}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const updateQuotation = async (id, data) => {
  try {
    const url = `${API.URL_QUOTATION}/${id}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const getQuotationPdf = async (id) => {
  try {
    const url = `${API.URL_QUOTATION}/pdf/${id}`;
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const getQuotationApproveSales = async (id, data) => {
  try {
    const url = `${API.URL_QUOTATION}/approve_sale_price/${id}`;
    const res = await axios.post(url, data);
    return res.data;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const getQuotationApprove = async (id, data) => {
  try {
    const url = `${API.URL_QUOTATION}/approve_quotation/${id}`;
    const res = await axios.post(url, data);
    return res.data;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};


export default slice;
