import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API from '../utils/api';
import encodeParams from '../utils/queryParam'

const initialState = {
  isLoading: false,
  data: {
    currentPage: 0,
    hasNext: false,
    hasPrevious: false,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    items: []
  },
  detail: {},
  contact: [],
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getList(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    setPending(state, action) {
      state.isLoading = true;
    },
    getById(state, action) {
      state.isLoading = false;
      state.detail = action.payload;
    },
    getContact(state, action) {
      state.isLoading = false;
      state.contact = action.payload;
    },
  }
});

export const reducer = slice.reducer;

export const getList = (orgId, businessId, parameter) => async (dispatch) => {
  dispatch(slice.actions.setPending());
  const url = `${API.URL_CUSTOMER}/${orgId}/action/GetCustomerListWithPaging/${businessId}?${encodeParams(parameter)}`
  const response = await axios.get(url);
  dispatch(slice.actions.getList(response.data.data));
};

export const getCustomerById = (orgId, businessId, id) => async (dispatch) => {
  dispatch(slice.actions.setPending());
  const url = `${API.URL_CUSTOMER}/${orgId}/action/GetCustomerInformation/${businessId}/${id}`
  const response = await axios.get(url);
  dispatch(slice.actions.getById(response.data.data));
};

export const getCustomerContact = (orgId, businessId, id) => async (dispatch) => {
  dispatch(slice.actions.setPending());
  const url = `${API.URL_CUSTOMER}/${orgId}/action/GetCustomerContactList/${businessId}/${id}`
  const response = await axios.get(url);
  dispatch(slice.actions.getContact(response.data.data));
};

export const createCustomer = async (orgId, data) => {
  try {
    const url = `${API.URL_CUSTOMER}/${orgId}/action/CreateCustomer`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const updateCustomer = async (orgId, businessId, id, data) => {
  try {
    const url = `${API.URL_CUSTOMER}/${orgId}/action/UpdateCustomer/${businessId}/${id}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const createCustomerContact = async (orgId, businessId, customerId, data) => {
  try {
    const url = `${API.URL_CUSTOMER}/${orgId}/action/AddCustomerContact/${businessId}/${customerId}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const updateCustomerContact = async (orgId, businessId, customerId, id, data) => {
  try {
    const url = `${API.URL_CUSTOMER}/${orgId}/action/UpdateCustomerContact/${businessId}/${customerId}/${id}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const deleteCustomerContact = async (orgId, data) => {
  try {
    const url = `${API.URL_CUSTOMER}/${orgId}/action/DeleteCustomerContact`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const deleteCustomer = async (orgId, data) => {
  try {
    const url = `${API.URL_CUSTOMER}/${orgId}/action/DeleteCustomer`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export default slice;
