// const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = 'https://localhost:7279'
// const BASE_URL = 'https://sales-api-dev.prom.co.th'
let BASE_URL = process.env.REACT_APP_BASE_URL;

if(window.location.hostname === "sales-dev.prom.co.th"){
  BASE_URL = 'https://sales-api-dev.prom.co.th'
}else if (window.location.hostname === "sales.prom.co.th"){
  BASE_URL = 'https://sales-api.prom.co.th'
} 

// BASE_URL = 'https://sales-api-dev.prom.co.th'

const API = {
  URL_AUTHORIZATION:  `${BASE_URL}/v1/api/Authorization/org`,
  URL_ORGANIZATION:  `${BASE_URL}/v1/api/Organization/org`,
  URL_CUSTOMER:  `${BASE_URL}/v1/api/Customer/org`,
  URL_PROJECT:  `${BASE_URL}/v1/api/Project/org`,
  URL_PRODUCT:  `${BASE_URL}/v1/api/Product/org`,
  URL_CONDITION:  `${BASE_URL}/v1/api/Condition/org`,
  URL_PAYMENT_ACCOUNT:  `${BASE_URL}/v1/api/PaymentAccount/org`,
  URL_QUOTATION:  `${BASE_URL}/v1/api/Quotation`,
  URL_MASTER:  `${BASE_URL}/v1/api/SystemConfig/org`,
}

export default API;