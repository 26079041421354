import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API from '../utils/api';
import encodeParams from '../utils/queryParam'

const initialState = {
  isLoading: false,
  data: {
    currentPage: 0,
    hasNext: false,
    hasPrevious: false,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    items: []
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getList(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      // state.data = {
      //   currentPage: 1,
      //   hasNext: false,
      //   hasPrevious: false,
      //   pageSize: 10,
      //   totalCount: action.payload.length,
      //   totalPages: Math.ceil((action.payload?.length || 0) / 10),
      //   items: action.payload.slice(0, 10)
      // }
    },
    setPending(state, action) {
      state.isLoading = true;
    },
  }
});

export const reducer = slice.reducer;

export const getList = (orgId, businessId, parameter) => async (dispatch) => {
  const url = `${API.URL_PRODUCT}/${orgId}/action/GetProductListWithPaging/${businessId}?${encodeParams(parameter)}`
  const response = await axios.get(url);
  dispatch(slice.actions.getList(response.data.data));
};

export default slice;
